import { cnn, time, times } from '../images/media';

export const testimonials = [
  {
    key: 'poetic',
    text: '“The Gift For Generations”',
    name: 'Lucia P.',
  },
  {
    key: 'personal',
    text: '“Perfect For A Priceless Moment”',
    name: 'Richard T.',
  },
  {
    key: 'cried',
    text: '“Forget Diamonds, Gift Love”',
    name: 'Karen L.',
  },
];

export const mediaQuotes = [
  {
    key: 'cnn',
    text: '“The moon is poised to inspire a future generation.”',
    image: cnn,
  },
  {
    key: 'time',
    text: '“The moon is so there - it’s a part of our lives”',
    image: time,
  },
  {
    key: 'nytimes',
    text: '“The moon inspires awe — its white blankness is the perfect backdrop for any kind of dream we might have”',
    image: times,
  },
];
