import { Flex, Image, Text } from '@chakra-ui/react';

import { mediaQuotes } from '~/assets/data/testimonials';
import { Column, Row } from '~/components/layout';

export const Media = () => {
  return (
    <Flex backgroundColor="primaryBg">
      <Row
        maxW="1000"
        mx="auto"
        gap="40px"
        paddingInline={{ base: '16px', md: '32px' }}
        paddingBlock={{ base: '32px', md: '60px' }}
        overflowX="scroll"
        align={'start'}
      >
        {mediaQuotes.map(media => (
          <Column
            flex={1}
            key={media.key}
            justifyContent="center"
            minWidth={{ base: '200px', md: 'auto' }}
          >
            <Image
              loading="lazy"
              src={media.image}
              height={{ base: '20px', md: '32px' }}
              mb="24px"
            />
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              textAlign="center"
              color="whiteAlpha.800"
            >
              {media.text}
            </Text>
          </Column>
        ))}
      </Row>
    </Flex>
  );
};
