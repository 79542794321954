import { Flex, Heading, Image, SimpleGrid, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { certified, happyWhite, moneyWhite, shutter } from '~/assets/icons';

import { animatedPrint } from '~/assets/images';
import { LandingCtaButton } from '~/components/buttons/LandingCtaButton';
import { Row } from '~/components/layout';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';

const paragraphs = [
  "Create a one-of-a-kind printed artwork that captures the exact appearance of the moon during your special moment. Using advanced technology, we'll create a high-resolution image of the moon as it appeared from the specific location and time you provide.",
  'This unique artwork makes for a timeless and meaningful gift for yourself, a friend, or a family member. With a range of elegant frames to choose from, you can display your artwork in a way that complements any décor.',
  "It's a beautiful way to immortalize your cherished memories and create a lasting tribute to the special moments in your life.",
];

const badges = [
  {
    key: 'paper',
    label: 'Printed on museum grade art matte paper',
    icon: certified,
  },
  {
    key: 'image',
    label: 'Ultra High Quality Satellite moon image',
    icon: shutter,
  },
  { key: 'money', label: 'Money-Back Guarantee', icon: moneyWhite },
  { key: 'satisfaction', label: '100% Satisfaction', icon: happyWhite },
];

interface IntroProps {
  ctaAction: VoidFunction;
  ctaLabel: string;
}

export const Intro: FC<IntroProps> = ({ ctaAction, ctaLabel }) => {
  const isLoading = useTransitionLoading();

  return (
    <Flex backgroundColor="#FFFFFF">
      <Flex
        align="center"
        maxW="1280"
        mx="auto"
        direction="column"
        paddingBlock={{ base: '40px', lg: '96px' }}
        paddingInline={{ base: '16px', md: '32px' }}
      >
        <Heading
          fontFamily="Libre Baskerville"
          fontSize={{ base: '3xl', md: '5xl' }}
          mb="60px"
          textAlign="center"
        >
          Cherish Your Moment: Printed Moon Artwork
        </Heading>
        <Flex
          align={{ base: 'center', md: 'start' }}
          flexDir={{ base: 'column', md: 'row' }}
          gap="32px"
          mb="40px"
        >
          <Image
            loading="lazy"
            maxHeight={{ base: '500px', md: '480px' }}
            width="auto"
            height="auto"
            src={animatedPrint}
          />
          <Flex
            flexDir="column"
            gap="24px"
            textAlign={{ base: 'center', md: 'start' }}
          >
            {paragraphs.map((paragraph, index) => (
              <Text key={index} fontSize={{ base: 'md', md: 'lg' }}>
                {paragraph}
              </Text>
            ))}
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap="16px" mb="16px">
              {badges.map(badge => (
                <Row key={badge.key} gap="8px" align="center">
                  <Flex
                    minWidth="40px"
                    padding="10px"
                    borderRadius="4px"
                    backgroundColor="primary"
                  >
                    <Image color="white" height="20px" src={badge.icon} />
                  </Flex>
                  <Text textAlign="start" fontWeight="bold">
                    {badge.label}
                  </Text>
                </Row>
              ))}
            </SimpleGrid>
          </Flex>
        </Flex>
        <LandingCtaButton
          isLoading={isLoading}
          onClick={ctaAction}
          label={ctaLabel}
        />
      </Flex>
    </Flex>
  );
};
