import { Image } from '@chakra-ui/react';

interface Props {
  imgSrc: string;
  containerHeight?: string;
}

export const DesktopCarousel = ({ containerHeight, imgSrc }: Props) => {
  return (
    <Image
      loading="lazy"
      src={imgSrc}
      objectFit="contain"
      display={{ base: 'none', md: 'block', lg: 'block' }}
      sx={{
        height: containerHeight ?? '70vh',
        '@media (min-height: 87.5rem)': {
          height: 'calc(100vh - 39.75rem)',
        },
      }}
    />
  );
};
