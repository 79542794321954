import { LaurealLeft } from '~/assets/icons/laureal_left';
import { Column, Row } from '../layout';
import { Text } from '@chakra-ui/react';
import { LaurealRight } from '~/assets/icons/laureal_right';

interface LaurealsProps {
  label: string;
}

export const Laureals: React.FC<LaurealsProps> = ({ label }) => {
  return (
    <Row align="center">
      <LaurealLeft color="white" height="56px" />
      <Column align="center" textAlign="center">
        <Text
          fontSize={{ base: 'xs', md: 'sm' }}
          color="white"
          fontWeight="600"
        >
          {label}
        </Text>
        <Text fontSize={{ base: 'xx-small', md: 'xs' }} color="whiteAlpha.600">
          2023
        </Text>
      </Column>
      <LaurealRight color="white" height="56px" />
    </Row>
  );
};
