import { Box, Flex, Text } from '@chakra-ui/react';

import { testimonials } from '~/assets/data/testimonials';
import { Stars } from '~/components/other/Stars';

export const Testimonials = () => {
  return (
    <Flex backgroundColor="primaryBg">
      <Flex
        maxW="1000"
        mx="auto"
        gap="40px"
        paddingInline={{ base: '16px', md: '32px' }}
        paddingBlock="60px"
        overflowX="scroll"
      >
        {testimonials.map(testimonial => (
          <Box key={testimonial.key} minW="130px">
            <Text
              fontFamily="Libre Baskerville"
              fontSize={{ base: 'xl', md: '2xl' }}
              color="white"
              mb="8px"
            >
              {testimonial.text}
            </Text>
            <Stars />
            <Text fontSize={{ base: 'sm', md: 'md' }} color="white" mt="8px">
              {testimonial.name}
            </Text>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};
