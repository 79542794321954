import { keyframes } from '@emotion/react';
import { Box } from '@chakra-ui/react';

interface Props {
  sliderWidthM: string;
  imgSrcM: string;
}

export const MobileCarousel = ({ imgSrcM, sliderWidthM }: Props) => {
  const slideMobile = keyframes({
    from: { transform: 'translateX(0px)' },
    to: { transform: `translateX(calc(${sliderWidthM} * -1))` },
  });

  return (
    <Box
      mt="16px"
      mb="24px"
      display={{ base: 'block', md: 'none', lg: 'none' }}
      overflow="hidden"
      width="100vw"
      height="180px"
    >
      <Box
        sx={{
          height: '100%',
          width: `calc(${sliderWidthM} * 2)`,
          background: `url(${imgSrcM}) repeat-x`,
          backgroundSize: 'contain',
          animation: `${slideMobile} 30s linear infinite`,
        }}
      />
    </Box>
  );
};
