import { Footer, Header } from '~/components';
import { Community } from '~/views/landing/Community';
import { Hero } from '~/views/landing/Hero';
import { MadeToLast } from '~/views/landing/MadeToLast';
import { Intro } from '~/views/landing/Intro';
import { StayInTouch } from '~/views/landing/StayInTouch';
import { Testimonials } from '~/views/landing/Testimonials';
import { HowItWorks } from '~/views/landing/HowItWorks';
import { UniverseOfMemories } from '~/views/landing/UniverseOfMemories';
import { Occasions } from '~/views/landing/Occasions';
import { Column } from '~/components/layout';
import { useNavigate } from '@remix-run/react';

import type { ActionArgs, MetaFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import { Suspense } from 'react';
import { Media } from '~/views/landing/Media';
import { Klaviyo } from '~/server/klaviyo';

const ctaLabel = 'Create My Lunar Art';

export const meta: MetaFunction = () => {
  return {
    title: 'Create the perfect gift for the priceless moment',
    description:
      "Create a one-of-a-kind artwork by entering the time and place of the moment that generations will celebrate. This unique artwork makes for a timeless and meaningful gift for yourself, a friend, or a family member. With a range of elegant frames to choose from, you can display your artwork in a way that complements any décor. It's a beautiful way to immortalize your cherished memories and create a lasting tribute to the special moments in your life",
  };
};

export async function action({ request }: ActionArgs) {
  const formData = await request.formData();

  const email = String(formData.get('email'));

  try {
    await Klaviyo.subscribeToNewsletter(email);

    return json({ emailSent: true });
  } catch (e) {
    return e;
  }
}

export default function Serverless() {
  const navigate = useNavigate();

  const ctaAction = () => navigate('/personalize');

  return (
    <>
      <Header />
      <Column as="main">
        <Hero
          ctaAction={ctaAction}
          ctaLabel={ctaLabel}
          title="Create the perfect gift for the priceless moment"
          subtitle="Create a one-of-a-kind artwork by entering the time and place of the moment that generations will celebrate."
        />
        <Media />
        <Intro ctaAction={ctaAction} ctaLabel={ctaLabel} />
        <Suspense>
          <Testimonials />
          <UniverseOfMemories ctaAction={ctaAction} ctaLabel={ctaLabel} />
          <HowItWorks ctaAction={ctaAction} ctaLabel={ctaLabel} />
          <Occasions ctaAction={ctaAction} ctaLabel={ctaLabel} />
          <Community ctaAction={ctaAction} ctaLabel={ctaLabel} />
          <MadeToLast ctaAction={ctaAction} ctaLabel={ctaLabel} />
          <StayInTouch />
        </Suspense>
      </Column>
      <Footer />
    </>
  );
}
