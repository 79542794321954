import { Flex, FormControl, FormLabel, Heading, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { Form, useActionData } from '@remix-run/react';
import type { FC } from 'react';

import { backdrop } from '~/assets/images';
import { BigButton } from '~/components/buttons/BigButton';
import { TextInput } from '~/components/inputs/TextInput';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';

export const StayInTouch: FC = () => {
  const data = useActionData();
  const isLoading = useTransitionLoading();

  const emailSent = data?.emailSent || false;

  return (
    <Flex
      backgroundImage={backdrop}
      backgroundAttachment="fixed"
      backgroundPosition="left"
    >
      <Flex maxW="1280" mx="auto">
        <Flex
          paddingInline={{ base: '16px', md: '32px' }}
          paddingBlock="80px"
          align="center"
          textAlign={{ base: 'center', md: 'start' }}
          flexDir="column"
          justify="center"
        >
          <Heading
            fontFamily="Libre Baskerville"
            fontSize={{ base: '3xl', md: '5xl' }}
            color="white"
            mb="24px"
          >
            Moon Lovers Community
          </Heading>
          <Text
            fontSize={{ base: 'lg', md: 'xl' }}
            color="whiteAlpha.800"
            mb="40px"
          >
            {emailSent
              ? 'Thank you for joining our club!'
              : 'Join for a chance to receive limited edition moon artworks and other special offers.'}
          </Text>
          {!emailSent && (
            <StyledForm method="post">
              <FormControl
                variant="floating"
                id="email"
                isRequired
                alignItems="center"
              >
                <TextInput
                  placeholder=" "
                  name="email"
                  type="email"
                  borderColor="green.500"
                  bgColor="whiteAlpha.50"
                  color="white"
                />
                {/* It is important that the Label comes after the Control due to css selectors */}
                <FormLabel color="whiteAlpha.600">Your Email</FormLabel>
              </FormControl>

              <BigButton isLoading={isLoading} type="submit">
                SIGN UP
              </BigButton>
            </StyledForm>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const StyledForm = styled(Form)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: auto 0;
  width: 100%;
  max-width: 580px;
`;
