import { Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import type { FC } from 'react';

import { desktopHero, mobileCarousel } from '~/assets/images/carousel';
import { Stars } from '~/components/other/Stars';
import { LandingCtaButton } from '~/components/buttons/LandingCtaButton';

import { MobileCarousel } from './components/MobileCarousel';
import { DesktopCarousel } from './components/DesktopCarousel';
import { backdrop } from '~/assets/images';
import { useTransitionLoading } from '~/hooks/useTransitionLoading';
import { Column, Row } from '~/components/layout';
import { Laureals } from '~/components/indicators/Laureals';

interface HeroProps {
  ctaAction: VoidFunction;
  ctaLabel: string;
  subtitle: string;
  title: string;
}

export const Hero: FC<HeroProps> = ({
  ctaAction,
  ctaLabel,
  subtitle,
  title,
}) => {
  const isLoading = useTransitionLoading();

  return (
    <Flex backgroundColor="#020D1D" paddingTop="80px">
      <Flex
        maxW="1280"
        backgroundImage={backdrop}
        backgroundSize="cover"
        mx="auto"
      >
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }}>
          <MobileCarousel sliderWidthM="1100.6666px" imgSrcM={mobileCarousel} />
          <Flex
            paddingInline={{ base: '16px', md: '32px' }}
            align={{ base: 'center', md: 'start' }}
            textAlign={{ base: 'center', md: 'start' }}
            flexDir="column"
            justify="center"
          >
            <Heading
              fontFamily="Libre Baskerville"
              fontSize={{ base: '3xl', md: '6xl' }}
              color="white"
              mb="24px"
            >
              {title}
            </Heading>
            <Text
              fontSize={{ base: 'md', md: 'lg' }}
              color="whiteAlpha.800"
              mb="40px"
            >
              {subtitle}
            </Text>

            <LandingCtaButton
              isLoading={isLoading}
              onClick={ctaAction}
              inverted
              label={ctaLabel}
            />

            <Column mt="40px" align="left" mb="24px">
              <Row
                gap="12px"
                align="center"
                justify={{ base: 'center', md: 'start' }}
              >
                <Text
                  color="white"
                  fontSize={{ base: 'lg', md: 'xl' }}
                  fontWeight="600"
                >
                  Excellent
                </Text>
                <Stars />
              </Row>
              <Text
                color="whiteAlpha.800"
                fontSize={{ base: 'md', md: 'lg' }}
                mb="40px"
              >
                17k moments captured
              </Text>
              <Row gap="16px" mb="24px">
                <Laureals label="Top 5 Wedding Gift" />
                <Laureals label="1# TikTok Trend" />
              </Row>
              <Text
                color="whiteAlpha.800"
                fontWeight="semibold"
                fontSize={{ base: 'sm', md: 'md' }}
              >
                🇺🇸 Printed and Shipped within the USA 🇺🇸
              </Text>
            </Column>
          </Flex>
          <DesktopCarousel imgSrc={desktopHero} />
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};
